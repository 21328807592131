import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './components/App'
import ErrorBoundry from './components/error-boundry'

import './components/font-awesome/scss/font-awesome.scss'
import './scss/_animate.scss'
import './scss/default.scss'
import { EchListProvider } from './context/EchListContext'

const rootNode = document.getElementById('root')
const root = createRoot(rootNode)

root.render(
  <ErrorBoundry>
    <EchListProvider>
      <App />
    </EchListProvider>
  </ErrorBoundry>
)
