import classNames from 'classnames/bind'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useEchList, useEchListDispatch } from '../../../context/EchListContext'
import Spacer from '../Spacer'
import Text from '../Text'

import styles from './Ech-filter.module.scss'

const cx = classNames.bind(styles)

const EchFilter = () => {
  const state = useEchList()
  const dispatch = useEchListDispatch()
  const { activeFilter } = state

  const activateFilter = activeFilter => {
    dispatch({
      type: 'FETCH_ECH_FILTER',
      payload: activeFilter,
    })
  }

  const filterTabClick = type => {
    activateFilter(type)
  }

  const { t } = useTranslation()
  const buttons = [
    { nursery: 'all', label: t('Groups.All'), isActive: false },
    {
      nursery: 'zoologica',
      label: t('Groups.ZOOLogiCaTitle'),
      isActive: false,
    },
    { nursery: 'nature', label: t('Groups.NatureTitle'), isActive: false },
    {
      nursery: 'hans-barth',
      label: t('Groups.HansBarthTitle'),
      isActive: false,
    },
    { nursery: 'dennerle', label: t('Groups.DennerleTitle'), isActive: false },
    {
      nursery: 'oriental',
      label: t('Groups.OrientalAquariumTitle'),
      isActive: false,
    },
    { nursery: 'kermit', label: t('Groups.KermitTitle'), isActive: false },
    { nursery: 'filippov', label: t('Groups.FilippovTitle'), isActive: false },
    { nursery: 'kras-aqua', label: t('Groups.KrasAquaTitle'), isActive: false },
    { nursery: 'hirurg', label: t('Groups.HirurgTitle'), isActive: false },
    { nursery: 'other', label: t('Groups.OtherTitle'), isActive: false },
  ]
  return (
    <Spacer marginBottom="large" className={styles.filter}>
      {buttons.map(button => {
        button.nursery === activeFilter
          ? (button.isActive = true)
          : (button.isActive = false)
        return (
          <button
            key={button.nursery}
            className={styles[`${button.isActive ? 'buttonActive' : 'button'}`]}
            type={button.nursery}
            onClick={() => filterTabClick(button.nursery)}
          >
            <Text
              weight={400}
              isUpperCase
              isWordWrapped
              fontSize={12}
              colorClass={cx('white')}
            >
              {button.label.toUpperCase()}
            </Text>
          </button>
        )
      })}
    </Spacer>
  )
}

export default EchFilter
