import { scroller } from 'react-scroll'

const scrollTo = selector => {
  const goToContainer = new Promise(resolve => {
    resolve()
  })
  goToContainer.then(() =>
    scroller.scrollTo(selector, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  )
}

const useScrollTo = event => {
  const newPlace = event.target.closest('[data-state]').dataset.state
  scrollTo(newPlace)
}

export default scrollTo
export { useScrollTo }
