import React from 'react'

import styles from './Video-frame.module.scss'

/*export default class _VideoFrame extends Component {
  state = {
    loading: true,
  }
  componentDidMount() {
    this.setState({
      loading: false,
    })
  }
  render() {
    const { loading } = this.state
    const { src } = this.props
    const spinner = loading ? <Spinner /> : null
    const content = !loading ? (
      <iframe
        className={styles.videoFrame}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      />
    ) : null
    return (
      <ErrorBoundry>
        {spinner}
        {content}
      </ErrorBoundry>
    )
  }
}*/

const VideoFrame = ({ src }) => {
  return (
    <iframe
      title="video"
      className={styles.videoFrame}
      src={src}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    />
  )
}

export default VideoFrame
