import React from 'react'

import ShakeScrollBottom from '../Shake-scroll-bottom'
import Spacer from '../Spacer'
import Text from '../Text'

import textStyles from '../Text/Text.module.scss'

import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <Spacer
      paddingTop="large+4"
      paddingBottom="large+3"
      className={styles.footer}
    >
      <ShakeScrollBottom />
      <Spacer marginTop="large">
        <Text className={textStyles.mainText}>
          &copy; {new Date().getFullYear()} Black Water Aquarium Channel. All
          rights reserved.
        </Text>
      </Spacer>
      <Spacer marginTop="large" marginBottom="large">
        <Text fontSize={12} className={textStyles.mainText}>
          Designed by AbsentSilence. Black Water Aquarium Channel.
        </Text>
      </Spacer>
    </Spacer>
  )
}

export default Footer
