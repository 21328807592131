import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { AuthContext } from '../../../context/AuthContext'
import useHttp from '../../../hooks/useHttp'
import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import Input from '../../primitives/Input'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'

import textStyles from '../../primitives/Text/Text.module.scss'

import styles from './AuthPage.module.scss'

const AuthPage = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  const auth = useContext(AuthContext)
  const { loading, request, error, clearError } = useHttp()

  useEffect(() => {
    clearError()
  }, [error, clearError])

  const [userForm, setUserForm] = useState({
    email: '',
    password: '',
  })

  const getFormValue = (value, name) => {
    userForm[name] = value
    setUserForm(userForm)
  }

  /*  const registerHandler = async () => {
        try {
          const data = await request('/api/auth/register', 'POST', userForm)
        } catch (e) {}
      }*/

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', userForm)
      auth.login(data.token, data.userId)
    } catch (e) {}
  }

  const { email, password } = userForm

  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
            {t('AuthPage.Authorization')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4" className={cn('page-content')}>
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (8).png')} alt="19" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('AuthPage.LoginData')}
          </Text>
          <div className={styles.row}>
            <div className={styles.formGroup}>
              <Input
                placeholder={t('AuthPage.EmailPlaceholder')}
                type="text"
                name="email"
                formValue={email}
                getFormValue={getFormValue}
              />
              <Input
                placeholder={t('AuthPage.PasswordPlaceholder')}
                type="password"
                name="password"
                formValue={password}
                getFormValue={getFormValue}
              />
            </div>
            <button
              className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
              disabled={loading}
              onClick={loginHandler}
            >
              {t('AuthPage.Login')}
            </button>
            {/*                <button
                  className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
                  onClick={registerHandler}
                  disabled={loading}
                >
                  Registration
                </button>*/}
          </div>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default AuthPage
