import { createContext, useContext, useReducer } from 'react'

export const EchListContext = createContext(null)
export const EchListDispatchContext = createContext(null)

const initialState = {
  echs: [],
  loading: true,
  error: null,
  activeFilter: 'all',
}

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_ECH_SUCCESS': {
      return {
        ...state,
        echs: action.payload,
        loading: false,
        error: null,
      }
    }
    case 'FETCH_ECH_FAILURE': {
      return {
        ...state,
        echs: [],
        loading: false,
        error: action.payload,
      }
    }
    case 'FETCH_ECH_FILTER': {
      return {
        ...state,
        loading: false,
        error: null,
        activeFilter: action.payload,
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

export function EchListProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <EchListContext.Provider value={state}>
      <EchListDispatchContext.Provider value={dispatch}>
        {children}
      </EchListDispatchContext.Provider>
    </EchListContext.Provider>
  )
}

export function useEchList() {
  return useContext(EchListContext)
}

export function useEchListDispatch() {
  return useContext(EchListDispatchContext)
}
