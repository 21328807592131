import React from 'react'

import Container from '../Container'
import Spacer from '../Spacer'

import styles from './PageСontent.module.scss'

const PageContent = ({ children }) => {
  return (
    <Spacer
      data-aos="fade-bottom"
      data-aos-delay="200"
      marginTop="large+3"
      paddingLeft="large"
      paddingRight="large"
      className={styles.content}
    >
      <Container>{children}</Container>
    </Spacer>
  )
}

export default PageContent
