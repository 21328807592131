import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import { useScrollTo } from '../../../hooks/useScrollTo'
import {
  // eslint-disable-next-line no-unused-vars
  getEchs,
  createEch,
  editEch,
} from '../../../services/echinodoruses-service'
import ErrorIndicator from '../../Error-indicator'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import EchDescription from '../../primitives/EchDescription'
import Input from '../../primitives/Input'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Spinner from '../../primitives/Spinner'
import Text from '../../primitives/Text'
import textStyles from '../../primitives/Text/Text.module.scss'

import TextArea from '../../primitives/TextArea'

import styles from './Admin.module.scss'

const GetEchs = ({ echs, addEchToForm }) => {
  return (
    <>
      {echs.map(ech => {
        return (
          <EchDescription key={ech._id} ech={ech} addEchToForm={addEchToForm} />
        )
      })}
    </>
  )
}

const Admin = ({ echs, mobileMenuOpened }) => {
  const { t } = useTranslation()
  const [echForm, setEchForm] = useState({
    _idForm: '',
    idForm: '',
    priceForm: '',
    availabilityForm: '',
    isHybridForm: '',
    nurseryForm: '',
    nameForm: '',
    nameUaForm: '',
    nameRuForm: '',
    heightForm: '',
    widthForm: '',
    descriptionForm: '',
    descriptionSecondForm: '',
    descriptionThirdForm: '',
  })

  const getFormValue = (value, name) => {
    echForm[name] = value
    setEchForm(echForm)
  }

  const addEchToForm = ech => {
    const {
      _id,
      id,
      price,
      availability,
      isHybrid,
      nursery,
      name,
      nameUa,
      nameRu,
      height,
      width,
      descriptionUa,
      descriptionRu,
      description,
    } = ech

    setEchForm({
      _idForm: _id,
      idForm: id,
      priceForm: price,
      availabilityForm: availability,
      isHybridForm: isHybrid,
      nurseryForm: nursery,
      nameForm: name,
      nameUaForm: nameUa,
      nameRuForm: nameRu,
      heightForm: height,
      widthForm: width,
      descriptionForm: descriptionUa,
      descriptionSecondForm: descriptionRu,
      descriptionThirdForm: description,
    })
  }

  const reset = () => {
    setEchForm({
      _idForm: '',
      idForm: '',
      priceForm: '',
      availabilityForm: '',
      isHybridForm: '',
      nurseryForm: '',
      nameForm: '',
      nameUaForm: '',
      nameRuForm: '',
      heightForm: '',
      widthForm: '',
      descriptionForm: '',
      descriptionSecondForm: '',
      descriptionThirdForm: '',
    })
  }

  const submitForm = (echForm, e) => {
    const { _idForm } = echForm

    !_idForm ? createEch(echForm, e) : editEch(echForm, e)
  }

  const {
    _idForm,
    idForm,
    priceForm,
    availabilityForm,
    isHybridForm,
    nurseryForm,
    nameForm,
    nameUaForm,
    nameRuForm,
    heightForm,
    widthForm,
    descriptionForm,
    descriptionSecondForm,
    descriptionThirdForm,
  } = echForm

  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
            Admin Panel
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="small">
          <Link to="farm" data-state="page-top" onClick={useScrollTo}>
            <ServiceIcon>
              <img
                src={require('../../img/bw.jpg')}
                alt=""
                style={{ height: '200px' }}
              />
            </ServiceIcon>
          </Link>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('Farm.Title')}
          </Text>
          <Divider />
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (1).png')} alt="" />
            <img src={require('../../img/icons/G12 (30).png')} alt="" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            Echinodoruses Data Base
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+4">
            <h2>Configure the Base:</h2>
            <form name="echForm">
              <Input type="hidden" name="_idForm" formValue={_idForm} />
              <div className={styles.formGroupFirst}>
                <div className={styles.formGroup}>
                  <label htmlFor="id">id:</label>
                  <Input
                    name="idForm"
                    formValue={idForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="price">price:</label>
                  <Input
                    name="priceForm"
                    formValue={priceForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="availability">availability:</label>
                  <Input
                    name="availabilityForm"
                    formValue={availabilityForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="isHybrid">isHybrid:</label>
                  <Input
                    name="isHybridForm"
                    formValue={isHybridForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="nursery">nursery:</label>
                  <Input
                    name="nurseryForm"
                    formValue={nurseryForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="height">height:</label>
                  <Input
                    name="heightForm"
                    formValue={heightForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="width">width:</label>
                  <Input
                    name="widthForm"
                    formValue={widthForm}
                    getFormValue={getFormValue}
                  />
                </div>
              </div>
              <div className={styles.formGroupSecond}>
                <div className={styles.formGroup}>
                  <label htmlFor="name">name:</label>
                  <Input
                    name="nameForm"
                    formValue={nameForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="nameUa">nameUa:</label>
                  <Input
                    name="nameUaForm"
                    formValue={nameUaForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="nameRu">nameRu:</label>
                  <Input
                    name="nameRuForm"
                    formValue={nameRuForm}
                    getFormValue={getFormValue}
                  />
                </div>
              </div>
              <div className={styles.formGroupThird}>
                <div className={styles.formGroup}>
                  <label htmlFor="description">description:</label>
                  <TextArea
                    name="descriptionThirdForm"
                    formValue={descriptionThirdForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="descriptionUa">descriptionUa:</label>
                  <TextArea
                    name="descriptionForm"
                    formValue={descriptionForm}
                    getFormValue={getFormValue}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="descriptionRu">descriptionRu:</label>
                  <TextArea
                    name="descriptionSecondForm"
                    formValue={descriptionSecondForm}
                    getFormValue={getFormValue}
                  />
                </div>
              </div>
              <div className={styles.panelBody}>
                <button
                  onClick={e => submitForm(echForm, e)}
                  className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
                >
                  Save
                </button>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  id="reset"
                  className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
                  onClick={reset}
                >
                  Drop
                </a>
              </div>
            </form>
            <GetEchs echs={echs} addEchToForm={addEchToForm} />
          </Spacer>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

const AdminContainer = () => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState

  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <ErrorIndicator />
  }
  return <Admin echs={echs} />
}

export default AdminContainer
