import React from 'react'
import { Link } from 'react-router-dom'

// import AutoSizer from 'react-virtualized-auto-sizer'
// import { FixedSizeList as List } from 'react-window'

import { useEchList } from '../../../context/EchListContext'
import { useScrollTo } from '../../../hooks/useScrollTo'

import ErrorIndicator from '../../Error-indicator'
import EchLiItem from '../Ech-li-item'
import Spinner from '../Spinner'

import styles from './Ech-list.module.scss'

const EchList = ({ echs, activeFilter }) => {
  return (
    <div className={styles.list}>
      {echs
        .filter(ech => ech.name.length > 0)
        .filter(ech => {
          if (activeFilter !== 'all') {
            return ech.nursery === activeFilter
          }
          return ech
        })
        .map(ech => {
          return (
            <Link
              key={ech.id}
              to={`/echinodorus/${ech.id}/`}
              data-state="page-top"
              onClick={useScrollTo}
            >
              <EchLiItem ech={ech} />
            </Link>
          )
        })}
    </div>
  )
}

/*const EchList = ({ echs, activeFilter }) => {
  let itemCount = 0
  const Row = ({ index, styles }) => {
    const items = (echs, activeFilter) => {
      return echs
        .filter(ech => ech.name.length > 0)
        .filter(ech => {
          if (activeFilter !== 'all') {
            return ech.nursery === activeFilter
          }
          return ech
        })
    }

    const ech = items(echs, activeFilter)[index]
    itemCount = items(echs, activeFilter).length
    return (
      <Link
        key={ech.id}
        style={styles}
        to={`/echinodorus/${ech.id}/`}
        data-state="page-top"
        onClick={useScrollTo}
      >
        <EchLiItem ech={ech} />
      </Link>
    )
  }
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="List"
          height={height}
          itemCount={5}
          itemSize={70}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}*/

const EchListContainer = () => {
  const echLoadingState = useEchList()
  const { echs, activeFilter, loading, error } = echLoadingState
  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <ErrorIndicator />
  }
  return <EchList echs={echs} activeFilter={activeFilter} />
}

export default EchListContainer
