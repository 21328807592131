import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Collection from '../../primitives/Collection'
import Divider from '../../primitives/Divider'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'

const Farm = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
            {t('Farm.Title')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <ServiceIcon>
              <img src={require('../../img/icons/G12 (9).png')} alt="" />
              <img src={require('../../img/icons/G12 (11).png')} alt="" />
            </ServiceIcon>
          </ServiceIcon>
          <Spacer marginTop="large+4">
            <Collection />
          </Spacer>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default Farm
