const getEchs = () => {
  return new Promise(resolve => {
    fetch('/api/echs/').then(response => {
      response.json().then(data => {
        data.sort((a, b) => a.id - b.id)
        // console.log('data', data)
        resolve(data)
      })
    })
  })
}

const getEchId = _id => {
  fetch('/api/echs/' + _id).then(response => {
    response.json()
  })
}

const createEch = (echForm, e) => {
  const {
    idForm,
    priceForm,
    availabilityForm,
    isHybridForm,
    nurseryForm,
    nameForm,
    nameUaForm,
    nameRuForm,
    heightForm,
    widthForm,
    descriptionForm,
    descriptionSecondForm,
    descriptionThirdForm,
  } = echForm

  const ech = {
    id: idForm,
    price: priceForm,
    availability: availabilityForm,
    isHybrid: isHybridForm,
    nursery: nurseryForm,
    name: nameForm,
    nameUa: nameUaForm,
    nameRu: nameRuForm,
    height: heightForm,
    width: widthForm,
    descriptionUa: descriptionForm,
    descriptionRu: descriptionSecondForm,
    description: descriptionThirdForm,
  }

  fetch('/api/echs/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    body: JSON.stringify(ech),
  }).then(response => {
    response.json().then(() => window.location.reload())
  })

  e.preventDefault()
}

const editEch = (echForm, e) => {
  const {
    _idForm,
    idForm,
    priceForm,
    availabilityForm,
    isHybridForm,
    nurseryForm,
    nameForm,
    nameUaForm,
    nameRuForm,
    heightForm,
    widthForm,
    descriptionForm,
    descriptionSecondForm,
    descriptionThirdForm,
  } = echForm

  const ech = {
    _id: _idForm,
    id: idForm,
    price: priceForm,
    availability: availabilityForm,
    isHybrid: isHybridForm,
    nursery: nurseryForm,
    name: nameForm,
    nameUa: nameUaForm,
    nameRu: nameRuForm,
    height: heightForm,
    width: widthForm,
    descriptionUa: descriptionForm,
    descriptionRu: descriptionSecondForm,
    description: descriptionThirdForm,
  }

  fetch('/api/echs', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    body: JSON.stringify(ech),
  }).then(response => {
    response.json().then(() => window.location.reload())
  })

  e.preventDefault()
}

const deleteEch = _id => {
  fetch('/api/echs/' + _id, {
    contentType: 'application/json',
    method: 'DELETE',
  }).then(response => {
    response.json().then(() => window.location.reload())
  })
}

export default getEchs
export { getEchId, createEch, editEch, deleteEch }
