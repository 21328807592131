/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames'
import classNames from 'classnames/bind'
import i18next from 'i18next'
import React, { useState } from 'react'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'

import useImageRequire from '../../../hooks/useImageRequire'
import Spacer from '../Spacer'
import Text from '../Text'

import textStyles from '../Text/Text.module.scss'

import styles from './Ech-li-item.module.scss'

const cx = classNames.bind(styles)
const EchLiItem = ({ ech }) => {
  const { t } = useTranslation()
  const { id, name, nameUa, nameRu, height, availability } = ech
  const { firstImageAdress } = useImageRequire(id)
  const location = useLocation()
  let language

  const [nameTranslation, setNameTranslation] = useState(null)

  useEffect(() => {
    checkTranslation()
  }, [location])

  const checkTranslation = () => {
    try {
      const value = localStorage.getItem('language')
      if (value) {
        language = JSON.parse(value)
      }
    } catch (error) {}

    switch (language) {
      case 'ua':
        setNameTranslation(nameUa)
        break
      case 'ru':
        setNameTranslation(nameRu)
        break
      case 'en':
        setNameTranslation(name)
        break
      default:
        setNameTranslation(nameUa)
        break
    }
  }

  i18next.on('languageChanged', function () {
    checkTranslation()
  })

  return (
    <Spacer
      marginBottom="large+4"
      key={id}
      className={cn(styles.item, textStyles.mainText)}
    >
      <Spacer marginBottom="large" className={styles.wrapper}>
        <img src={firstImageAdress} alt="" className={styles.img} />
      </Spacer>
      <Text fontSize={12} colorClass={cx(['ech-li-item'])}>
        id: {id}
      </Text>
      <Text isUpperCase isWordWrapped weight={700} fontSize={12}>
        {name}
      </Text>
      <Text isWordWrapped fontSize={12} colorClass={cx(['ech-li-translated'])}>
        {nameTranslation}
      </Text>
      <Text fontSize={12} colorClass={cx(['ech-li-item'])}>
        {height
          ? `${t('EchLiItem.Height')} ${height} ${t('EchLiItem.CM')}`
          : ''}
      </Text>
      <Text fontSize={12} colorClass={cx(['ech-li-item'])}>
        {availability ? t('EchLiItem.Present') : t('EchLiItem.Absent')}
      </Text>
    </Spacer>
  )
}

export default EchLiItem
