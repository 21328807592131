function useRandomPromoEchs(echs) {
  //Random 5 echinodoruses show
  const from = 1
  const to = echs.length
  const n = 5
  return [...Array(to - from + 1).keys()]
    .map(i => i + from)
    .reduce(
      // eslint-disable-next-line no-sequences
      (arr, elt) => (arr.splice(Math.random() * (arr.length + 1), 0, elt), arr),
      []
    )
    .slice(0, n)
}

export default useRandomPromoEchs
