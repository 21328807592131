import cn from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../../context/AuthContext'
import { useScrollTo } from '../../../hooks/useScrollTo'

import SearchPanel from '../../primitives/Search-panel'
import Angle from '../Angle'

import angleStyles from '../Angle/Angle.module.scss'
import LanguageSwitcher from '../LanguageSwitcher'
import NavigationDropdown from '../Navigation-dropdown'
import NavigationLink from '../NavigationLink'
import navigationLinkStyles from '../NavigationLink/NavigationLink.module.scss'
import Spacer from '../Spacer'
import Text from '../Text'
import textStyles from '../Text/Text.module.scss'

import styles from './Navigation.module.scss'

const Navigation = ({ checkMenuState }) => {
  const { t } = useTranslation()
  const [collapse, setCollapse] = useState(false)
  const [isMobileMenuShowed, setIsMobileMenuShowed] = useState(false)

  const auth = useContext(AuthContext)
  const { isAuthenticated } = auth

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setCollapse(true)
      } else {
        setCollapse(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    checkMenuState(isMobileMenuShowed)
  }, [checkMenuState, isMobileMenuShowed])

  const mobileMenuToggle = () => {
    setIsMobileMenuShowed(!isMobileMenuShowed)
  }

  return (
    <nav
      className={cn(
        styles.navbar,
        styles.custom,
        styles.fixed,
        collapse ? styles.collapse : ''
      )}
      role="navigation"
    >
      <Spacer className={styles.width}>
        <Spacer className={cn(styles.header)}>
          <button
            type="button"
            className={cn(collapse ? styles.collapsedToggle : styles.toggle)}
            data-toggle="dropdown"
            onClick={() => mobileMenuToggle()}
          >
            <Angle className={angleStyles.bars} />
          </button>
          <Link
            to="/"
            data-state="page-top"
            className={styles.flex}
            onClick={useScrollTo}
          >
            <div
              className={cn(styles.logo, collapse ? styles.collapsedLogo : '')}
            />
            <Text
              isUpperCase
              weight={700}
              fontSize={18}
              className={cn(textStyles.brand, collapse ? styles.collapse : '')}
            >
              <Spacer marginTop="small-1" marginLeft="medium">
                Black Water
              </Spacer>
              <Spacer marginLeft="medium">Echinodorus</Spacer>
            </Text>
          </Link>
        </Spacer>
        <Spacer className={cn(styles.right)}>
          <ul
            className={cn(
              isMobileMenuShowed
                ? [styles.nav, styles.flex]
                : [styles.nav /*styles.mobileNone*/],
              collapse ? styles.collapse : ''
            )}
          >
            <SearchPanel />
            <NavigationLink
              to="/catalog/"
              dataState="page-top"
              text={t('NavigationLink.Catalog')}
              className={cn(
                navigationLinkStyles.navbarEch,
                collapse ? styles.collapsedEch : ''
              )}
            />
            <LanguageSwitcher />
            <NavigationDropdown
              text={t('Menu.Menu')}
              isMobileMenuShowed={isMobileMenuShowed}
            >
              <NavigationLink
                to="/catalog/"
                dataState="page-top"
                text={t('Menu.Catalog')}
              />
              <NavigationLink
                to="/"
                dataState="about"
                text={t('Menu.Groups')}
              />
              <NavigationLink
                to="/blog/"
                dataState="page-top"
                text={t('Menu.Blog')}
              />
              <NavigationLink
                to="/description/"
                dataState="page-top"
                text={t('Menu.GeneralDescription')}
              />
              <NavigationLink
                to="/"
                dataState="service"
                text={t('Menu.ServiceList')}
              />
              <NavigationLink
                to="/purchase/"
                dataState="page-top"
                text={t('Menu.Order')}
              />
              <NavigationLink
                to="/"
                dataState="contact"
                text={t('Menu.Contacts')}
              />
              {isAuthenticated ? (
                <NavigationLink
                  to="/admin/"
                  dataState="page-top"
                  text={t('Menu.Admin')}
                />
              ) : (
                <NavigationLink
                  to="/login/"
                  dataState="page-top"
                  text={t('Menu.AdminLogin')}
                />
              )}
            </NavigationDropdown>
          </ul>
        </Spacer>
      </Spacer>
      <div className={styles.flagTop}>{t('Navigation.Glory')}</div>
      <div className={styles.flagBottom}>{t('Navigation.Heroes')}</div>
    </nav>
  )
}

export default Navigation
