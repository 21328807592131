import PropTypes from 'prop-types'
import { createContext } from 'react'

const themeContextShape = PropTypes.shape({
  className: PropTypes.string,
})

const ThemeProviderContext = createContext({})

export default ThemeProviderContext
export { themeContextShape }
