function useImageRequire(id) {
  const requiredImageAdresses = {
    firstImageAdress: require('../components/img/mocked_images/1.jpg'),
    secondImageAdress: require('../components/img/mocked_images/2.jpg'),
    thirdImageAdress: require('../components/img/mocked_images/3.jpg'),
  }

  try {
    require(`../components/img/echinodorus/${id}/1.jpg`)
    requiredImageAdresses.firstImageAdress = require(`../components/img/echinodorus/${id}/1.jpg`)
  } catch (e) {}

  try {
    require(`../components/img/echinodorus/${id}/2.jpg`)
    requiredImageAdresses.secondImageAdress = require(`../components/img/echinodorus/${id}/2.jpg`)
  } catch (e) {}

  try {
    require(`../components/img/echinodorus/${id}/3.jpg`)
    requiredImageAdresses.thirdImageAdress = require(`../components/img/echinodorus/${id}/3.jpg`)
  } catch (e) {}

  return requiredImageAdresses
}

export default useImageRequire
