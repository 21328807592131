/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames'
import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import useImageRequire from '../../../hooks/useImageRequire'
import { useScrollTo } from '../../../hooks/useScrollTo'
import ErrorIndicator from '../../Error-indicator'
import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import EchListPromo from '../../primitives/Ech-list-promo'
import Image from '../../primitives/Image'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import NextPage from '../../primitives/Next-page'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import PreviousPage from '../../primitives/Previous-page'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Spinner from '../../primitives/Spinner'
import Text from '../../primitives/Text'

import textStyles from '../../primitives/Text/Text.module.scss'

import styles from './Ech-page.module.scss'

const EchItem = ({ echs, mobileMenuOpened }) => {
  const { t } = useTranslation()
  let language
  const [ech, setEch] = useState([])
  const [promo, setPromo] = useState(false)
  const location = useLocation()
  const id = parseInt(
    location.pathname.replace('echinodorus', '').replaceAll('/', '')
  )

  const { firstImageAdress, secondImageAdress, thirdImageAdress } =
    useImageRequire(id)

  const useChangePromo = event => {
    setPromo(!promo)
    useScrollTo(event)
  }

  const [translatedText, setTranslatedText] = useState({
    mainText: null,
    nameText: null,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkTranslation = () => {
    try {
      const value = localStorage.getItem('language')
      if (value) {
        language = JSON.parse(value)
      }
    } catch (error) {}

    switch (language) {
      case 'ua':
        setTranslatedText({ mainText: ech.descriptionUa, nameText: ech.nameUa })
        break
      case 'ru':
        setTranslatedText({
          mainText: ech.descriptionRu,
          nameText: ech.nameRu,
        })
        break
      case 'en':
        setTranslatedText({
          mainText: ech.description,
          nameText: ech.name,
        })
        break
      default:
        setTranslatedText({ mainText: ech.descriptionUa, nameText: ech.nameUa })
        break
    }
  }

  useEffect(() => {
    setEch(echs[Number(id) - 1])
    return () => checkTranslation()
  }, [echs, id, location])

  useEffect(() => checkTranslation(), [ech])

  i18next.on('languageChanged', function () {
    checkTranslation()
  })

  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PreviousPage
        id={id}
        useChangePromo={useChangePromo}
        data-state="page-top"
      />
      <NextPage id={id} useChangePromo={useChangePromo} data-state="page-top" />
      <PageHeader>
        <div className={styles.headerTitle}>
          <Spacer marginTop="large+3">
            <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
              {ech.name}
            </Text>
            <Spacer marginTop="large+4" marginBottom="medium">
              <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={16}>
                ({t('EchPage.Nursery')}: {ech.nursery})
              </Text>
            </Spacer>
          </Spacer>
          <Spacer marginTop="large+2" marginBottom="large">
            <Angle className={cn(angleStyles.down, angleStyles.x2)} />
          </Spacer>
        </div>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <Image src={firstImageAdress} alt="" style={{ height: '400px' }} />
          </ServiceIcon>
          <Text letterSpacing={1} isUpperCase fontSize={12}>
            id: {ech.id}
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+3">
            <Text letterSpacing={2} isUpperCase fontSize={20}>
              {translatedText.nameText}
            </Text>
          </Spacer>
          <Text letterSpacing={1} isUpperCase fontSize={12}>
            {ech.height
              ? `${t('EchLiItem.Height')} ${ech.height} ${t('EchLiItem.CM')}`
              : ''}
          </Text>
          <Spacer marginBottom="large+3">
            <Text
              letterSpacing={1}
              fontSize={12}
              className={textStyles.mainText}
            >
              {ech.availability ? t('EchLiItem.Present') : t('EchPage.Absent')}
            </Text>
          </Spacer>
          <Text
            isWordWrapped
            fontSize={14}
            className={cn(textStyles.justifyText, textStyles.mainText)}
          >
            <Spacer marginTop="large+2" marginBottom="large+2">
              <Image src={firstImageAdress} alt="1" />
            </Spacer>
            {translatedText.mainText}
            <Spacer marginTop="large+2" marginBottom="large+2">
              <Image src={secondImageAdress} alt="2" />
            </Spacer>
            <Spacer marginTop="large+2" marginBottom="large+2">
              <Image src={thirdImageAdress} alt="3" />
            </Spacer>
          </Text>
          <Spacer marginTop="large+4" marginBottom="large+4">
            <Text letterSpacing={1} isUpperCase fontSize={16}>
              {t('EchPage.Interesting')}
            </Text>
          </Spacer>
          <EchListPromo promo={promo} />
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

const EchItemContainer = () => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState

  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <ErrorIndicator />
  }
  if (echs.length > 0) {
    return <EchItem echs={echs} />
  } else {
    return <Spinner />
  }
}

export default EchItemContainer
