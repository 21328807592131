import useLocalStorage from '../../../hooks/useLocalstorage'
import i18n from '../../../i18n'

import styles from './LanguageSwitcher.module.scss'

const LanguageSwitcher = () => {
  const [language, setLanguage] = useLocalStorage('language', 'ua')
  const handleLenguageChange = e => {
    setLanguage(e.target.value)
    switch (e.target.value) {
      case 'en':
        i18n.changeLanguage('en')
        setLanguage('en')
        break
      case 'ua':
        i18n.changeLanguage('ua')
        setLanguage('ua')
        break
      case 'ru':
        i18n.changeLanguage('ru')
        setLanguage('ru')
        break
      default:
        i18n.changeLanguage('ua')
        setLanguage('ua')
        break
    }
  }
  return (
    <select
      className={styles.languageSwitcher}
      name="selectedFruit"
      value={language}
      onChange={e => handleLenguageChange(e)}
    >
      <option value="en">en</option>
      <option value="ua">ua</option>
      <option value="ru">ru</option>
    </select>
  )
}

export default LanguageSwitcher
