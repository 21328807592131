import { useEffect } from 'react'
function useUnHover(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        // console.log('hover into')
        return
      }
      handler(event)
    }
    document.addEventListener('mouseover', listener)
    return () => {
      document.removeEventListener('mouseover', listener)
    }
  }, [ref, handler])
}

export default useUnHover
