import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './MainContentWrapper.module.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
}
const MainContentWrapper = ({ mobileMenuOpened, children }) => {
  return (
    <div
      className={cn(
        mobileMenuOpened
          ? [styles.main, styles.mobileMenuIsOpened]
          : styles.main
      )}
    >
      {children}
    </div>
  )
}

MainContentWrapper.propTypes = propTypes

export default MainContentWrapper
