import cn from 'classnames'
import React from 'react'

import { Link } from 'react-scroll'

import Angle from '../Angle'
import angleStyles from '../Angle/Angle.module.scss'

import styles from './Shake-scroll-top.module.scss'

const ShakeScrollTop = () => {
  return (
    <div className={cn(styles.scroll, styles.shake, styles.animated)}>
      <Link
        to="contact"
        smooth={'true'}
        offset={0}
        duration={1000}
        className={styles.circle}
      >
        <Angle
          className={cn(
            angleStyles.fa,
            angleStyles.doubleDown,
            styles.animated
          )}
        />
      </Link>
    </div>
  )
}

export default ShakeScrollTop
