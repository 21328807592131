import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import Image from '../../primitives/Image'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'
import textStyles from '../../primitives/Text/Text.module.scss'
import VideoFrame from '../../primitives/Video-frame/index'

const Blog = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
            {t('Blog.Title')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4" marginBottom="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (26).png')} alt="" />
            <img src={require('../../img/icons/G12 (15).png')} alt="" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.EchTank')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/gjS0yZCx-fQ'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.Experiment')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/7zpA2w07JRQ'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.FromAir')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/c_IfVoJqBqU'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.Autumn')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/xfE0U_kZ0QU'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.Bloomed')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/cqzpIJxOVYA'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.Windowsill')}
            </Spacer>
            <VideoFrame src={'https://www.youtube.com/embed/0GNaykirus0'} />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.RubinNarrowLeaves')}
            </Spacer>
            <Image src={require('../../img/blog/1.jpg')} alt="1" />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.Aschersonianus')}
            </Spacer>
            <Image src={require('../../img/blog/2.jpg')} alt="2" />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.ReginaHildebrandt')}
            </Spacer>
            <Image src={require('../../img/blog/3.jpg')} alt="2" />
            <Spacer marginTop="large+4" marginBottom="large+2">
              {t('Blog.OcelotGreen')}
            </Spacer>
            <Image src={require('../../img/blog/4.jpg')} alt="2" />
          </Text>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default Blog
