import React from 'react'
import { Link } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import ErrorIndicator from '../../Error-indicator'
import Spinner from '../Spinner'

import styles from './Previous-page.module.scss'

const PreviousPage = ({ echs, id, useChangePromo }) => {
  return echs
    .filter(ech => ech.id === Number(id))
    .map(ech => {
      return (
        <Link
          key={ech.id === 1 ? echs.length : ech.id - 1}
          to={`/echinodorus/${ech.id === 1 ? echs.length : ech.id - 1}/`}
          data-state="page-top"
          onClick={useChangePromo}
        >
          <div className={styles.previous} />
        </Link>
      )
    })
}

const PreviousPageContainer = ({ id, useChangePromo }) => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState

  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <ErrorIndicator />
  }
  if (echs.length > 0) {
    return <PreviousPage echs={echs} id={id} useChangePromo={useChangePromo} />
  } else {
    return <Spinner />
  }
}

export default PreviousPageContainer
