import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import { useScrollTo } from '../../../hooks/useScrollTo'
import ErrorIndicator from '../../Error-indicator'
import CollectionFilter from '../Collection-filter'
import CollectionItem from '../Collection-item'
import Spinner from '../Spinner'
import Text from '../Text'
import textStyles from '../Text/Text.module.scss'

import styles from './Collection.module.scss'

const id = (a, b) => {
  const item_a = a.id
  const item_b = b.id
  if (item_a < item_b) {
    return -1
  }
}
const name = (a, b) => {
  const item_a = a.name.toLowerCase()
  const item_b = b.name.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const nameUa = (a, b) => {
  const item_a = a.nameUa.toLowerCase()
  const item_b = b.nameUa.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const nameRu = (a, b) => {
  const item_a = a.nameRu.toLowerCase()
  const item_b = b.nameRu.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const height = (a, b) => {
  const item_a = a.height
  const item_b = b.height
  if (item_a < item_b) {
    return -1
  }
}
const idReverse = (b, a) => {
  const item_a = a.id
  const item_b = b.id
  if (item_a < item_b) {
    return -1
  }
}
const nameReverse = (b, a) => {
  const item_a = a.name.toLowerCase()
  const item_b = b.name.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const nameUaReverse = (b, a) => {
  const item_a = a.nameUa.toLowerCase()
  const item_b = b.nameUa.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const nameRuReverse = (b, a) => {
  const item_a = a.nameRu.toLowerCase()
  const item_b = b.nameRu.toLowerCase()
  if (item_a < item_b) {
    return -1
  }
}
const heightReverse = (b, a) => {
  const item_a = a.height
  const item_b = b.height
  if (item_a < item_b) {
    return -1
  }
}
let fn

const Collection = ({
  echs,
  activeFilterCollection,
  sortCollectionTabsDirection,
}) => {
  const { t } = useTranslation()

  const echCollection = echs
    .filter(ech => ech.name.length > 0)
    .sort(fn)
    .filter(ech => {
      return ech.availability
    })

  if (sortCollectionTabsDirection === 'down') {
    switch (activeFilterCollection) {
      case 'name':
        fn = nameReverse
        break
      case 'nameUa':
        fn = nameUaReverse
        break
      case 'nameRu':
        fn = nameRuReverse
        break
      case 'height':
        fn = heightReverse
        break
      default:
        fn = idReverse
    }
  } else {
    switch (activeFilterCollection) {
      case 'name':
        fn = name
        break
      case 'nameUa':
        fn = nameUa
        break
      case 'nameRu':
        fn = nameRu
        break
      case 'height':
        fn = height
        break
      default:
        fn = id
    }
  }
  return (
    <Text
      isWordWrapped
      fontSize={14}
      className={cn(
        textStyles.justifyText,
        textStyles.mainText,
        styles.echCollection
      )}
    >
      {t('Collection.Collection')}
      {echCollection.length}
      <br />
      <CollectionFilter />
      {echCollection.map(ech => {
        return (
          <Link
            key={ech.id}
            to={`/echinodorus/${ech.id}/`}
            data-state="page-top"
            onClick={useScrollTo}
          >
            <CollectionItem ech={ech} />
          </Link>
        )
      })}
      <br />
    </Text>
  )
}

const CollectionContainer = ({
  activeFilterCollection,
  sortCollectionTabsDirection,
}) => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState
  if (loading) {
    return <Spinner />
  }
  if (error) {
    return <ErrorIndicator />
  }
  return (
    <Collection
      echs={echs}
      activeFilterCollection={activeFilterCollection}
      sortCollectionTabsDirection={sortCollectionTabsDirection}
    />
  )
}

export default CollectionContainer
