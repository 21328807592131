import cn from 'classnames'
import classNames from 'classnames/bind'
import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useScrollTo } from '../../../hooks/useScrollTo'
import animateStyles from '../../../scss/_animate.scss'
import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Container from '../../primitives/Container'
import containerStyles from '../../primitives/Container/Container.module.scss'
import Divider from '../../primitives/Divider'
import Image from '../../primitives/Image'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import ShakeScrollTop from '../../primitives/Shake-scroll-top'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'
import textStyles from '../../primitives/Text/Text.module.scss'
import TitleEchContainer from '../../primitives/TitleEchContainer'

import styles from './Home-page.module.scss'

const cx = classNames.bind(styles)

const HomePage = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const event = location.state
  const groupRef = useRef(null)
  const serviceRef = useRef(null)
  const contactRef = useRef(null)

  useEffect(() => {
    if (event && event.event === 'about') {
      groupRef.current.scrollIntoView()
    }
    if (event && event.event === 'service') {
      serviceRef.current.scrollIntoView()
    }
    if (event && event.event === 'contact') {
      contactRef.current.scrollIntoView()
    }
  }, [event])

  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Container className={styles.home}>
        <Intro data-screen-parallax data-parallax data-offset="15">
          <Text
            isUpperCase
            isWordWrapped
            fontSize={16}
            letterSpacing={1}
            className={styles.slogan}
            data-parallax
            data-offset="25"
          >
            <Spacer>{t('Home-page.IntroStart')}</Spacer>
            <Spacer>{t('Home-page.IntroEnd')}</Spacer>
          </Text>
        </Intro>
        <ShakeScrollTop />
        <Container
          id="about"
          className={cn(containerStyles.homeSection, textStyles.centeredText)}
        >
          <div id="group" ref={groupRef} />
          <PageHeader>
            <Spacer marginTop="large+3">
              <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
                {t('Home-page.Groups')}
              </Text>
            </Spacer>
            <Spacer marginTop="large+2" marginBottom="large">
              <Angle className={cn(angleStyles.down, angleStyles.x2)} />
            </Spacer>
          </PageHeader>
          <PageContent>
            <Divider />
            <Spacer className={cn(containerStyles.rowGrid)} marginTop="large+2">
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="100"
                filter="nature"
                titleText={t('Groups.NatureTitle')}
                mainText={t('Groups.Nature')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="200"
                filter="hans-barth"
                titleText={t('Groups.HansBarthTitle')}
                mainText={t('Groups.HansBarth')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="300"
                filter="dennerle"
                titleText={t('Groups.DennerleTitle')}
                mainText={t('Groups.Dennerle')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="400"
                filter="zoologica"
                titleText={t('Groups.ZOOLogiCaTitle')}
                mainText={t('Groups.ZOOLogiCa')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="500"
                filter="kermit"
                titleText={t('Groups.KermitTitle')}
                mainText={t('Groups.Kermit')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="600"
                filter="oriental"
                titleText={t('Groups.OrientalAquariumTitle')}
                mainText={t('Groups.OrientalAquarium')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="700"
                filter="filippov"
                titleText={t('Groups.FilippovTitle')}
                mainText={t('Groups.Filippov')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="800"
                filter="kras-aqua"
                titleText={t('Groups.KrasAquaTitle')}
                mainText={t('Groups.KrasAqua')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="900"
                filter="hirurg"
                titleText={t('Groups.HirurgTitle')}
                mainText={t('Groups.Hirurg')}
              />
              <TitleEchContainer
                className={cn(
                  containerStyles.boxedGrey,
                  animateStyles.bounceInUp
                )}
                data-aos="fade-up"
                data-aos-delay="1000"
                filter="other"
                titleText={t('Groups.OtherTitle')}
                mainText={t('Groups.Other')}
              />
            </Spacer>
          </PageContent>
        </Container>
        <Container
          id="service"
          className={cn(containerStyles.homeSection, textStyles.centeredText)}
        >
          <div id="serviceRef" ref={serviceRef} />
          <PageHeader>
            <Spacer marginTop="large+3">
              <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
                {t('Menu.Services')}
              </Text>
            </Spacer>
            <Spacer marginTop="large+2" marginBottom="large">
              <Angle className={cn(angleStyles.down, angleStyles.x2)} />
            </Spacer>
          </PageHeader>
          <PageContent>
            <Divider />
            <Spacer className={cn(containerStyles.row)} marginTop="large+2">
              <Spacer
                className={cn(
                  animateStyles.fadeInRight,
                  containerStyles.services
                )}
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <Link to="catalog" data-state="page-top" onClick={useScrollTo}>
                  <ServiceIcon>
                    <Image
                      src={require('../../img/icons/G12 (3).png')}
                      alt=""
                    />
                  </ServiceIcon>
                  <Spacer marginBottom="large">
                    <Text
                      letterSpacing={1}
                      isUpperCase
                      isWordWrapped
                      weight={400}
                      fontSize={12}
                      className={textStyles.centeredText}
                      colorClass={cx(styles.green)}
                    >
                      {t('Menu.Catalog')}
                    </Text>
                  </Spacer>
                  <Text
                    isWordWrapped
                    weight={400}
                    fontSize={14}
                    className={cn(textStyles.mainText)}
                    colorClass={cx(styles.green)}
                  >
                    {t('Menu.Varieties')}
                  </Text>
                </Link>
              </Spacer>
              <Spacer
                className={cn(
                  animateStyles.fadeInRight,
                  containerStyles.services
                )}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <Link to="purchase" data-state="page-top" onClick={useScrollTo}>
                  <ServiceIcon>
                    <Image
                      src={require('../../img/icons/G12 (19).png')}
                      alt=""
                    />
                  </ServiceIcon>
                  <Spacer marginBottom="large">
                    <Text
                      letterSpacing={1}
                      isUpperCase
                      isWordWrapped
                      weight={400}
                      fontSize={12}
                      className={textStyles.centeredText}
                      colorClass={cx(styles.green)}
                    >
                      {t('Menu.Purchase')}
                    </Text>
                  </Spacer>
                  <Text
                    isWordWrapped
                    weight={400}
                    fontSize={14}
                    className={cn(textStyles.mainText)}
                    colorClass={cx(styles.green)}
                  >
                    {t('Menu.Buy')}
                  </Text>
                </Link>
              </Spacer>
              <Spacer
                className={cn(
                  animateStyles.fadeInRight,
                  containerStyles.services
                )}
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <Link to="blog" data-state="page-top" onClick={useScrollTo}>
                  <ServiceIcon>
                    <Image
                      src={require('../../img/icons/G12 (15).png')}
                      alt=""
                    />
                  </ServiceIcon>
                  <Spacer marginBottom="large">
                    <Text
                      letterSpacing={1}
                      isUpperCase
                      isWordWrapped
                      weight={400}
                      fontSize={12}
                      className={textStyles.centeredText}
                      colorClass={cx(styles.green)}
                    >
                      {t('Menu.Blog')}
                    </Text>
                  </Spacer>
                  <Text
                    isWordWrapped
                    weight={400}
                    fontSize={14}
                    className={cn(textStyles.mainText)}
                    colorClass={cx(styles.green)}
                  >
                    {t('Menu.PhotoAndVideo')}
                  </Text>
                </Link>
              </Spacer>
            </Spacer>
          </PageContent>
        </Container>
        <Container
          id="contact"
          className={cn(
            containerStyles.homeSection,
            textStyles.centeredText,
            containerStyles.contactContainer
          )}
        >
          <div id="contactRef" ref={contactRef} />
          <PageHeader>
            <Spacer marginTop="large+3">
              <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
                {t('Menu.Connection')}
              </Text>
            </Spacer>
            <Spacer marginTop="large+2" marginBottom="large">
              <Angle className={cn(angleStyles.down, angleStyles.x2)} />
            </Spacer>
          </PageHeader>
          <PageContent>
            <Divider />
            <Container
              className={cn(containerStyles.contact)}
              data-state="contact"
            >
              <Spacer
                className={cn(textStyles.justifyText)}
                marginTop="large+2"
              >
                <Text fontSize={12} isUpperCase letterSpacing={1}>
                  {t('Menu.Contacts')}
                </Text>
                {/*<address>*/}
                {/*<strong>Phone:</strong><br/>*/}
                {/*<abbr title="Phone"/> <strong>+380(50)</strong>*/}
                {/*</address>*/}
                <Spacer className={textStyles.mainText}>
                  <Text fontSize={14}> {t('Menu.Email')}</Text>
                  <br />
                  <a
                    href="mailto:ech.blackwater@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text
                      fontSize={14}
                      colorClass={cx(styles.green)}
                      className={cn(styles.long)}
                    >
                      ech.blackwater@gmail.com
                    </Text>
                  </a>
                </Spacer>
                <Spacer className={textStyles.mainText}>
                  <Text fontSize={14}>{t('Menu.SocialNetworks')}</Text>
                  <br />
                  <ul className={containerStyles.social}>
                    {/*<li className={containerStyles.facebook}">
                   <a href="https://www.facebook.com/"
                   target="_blank">
                   <Angle className={angleStyles.facebook} />
                   </a>
                   </li>
                   <li className={containerStyles.instagram}>
                   <a href="https://www.instagram.com/"
                   target="_blank">
                   <Angle className={angleStyles.instagram} />
                   </a>
                   </li>*/}
                    <li className={containerStyles.yt}>
                      <a
                        href="https://www.youtube.com/channel/UCwZOLZBVu5GGdDPJNwsOTcQ/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Angle className={angleStyles.youtube} />
                      </a>
                    </li>
                  </ul>
                </Spacer>
              </Spacer>
              <Spacer
                marginTop="large+2"
                marginBottom="large+2"
                marginLeft="large+2"
              >
                <iframe
                  title="map"
                  className={containerStyles.googleMap}
                  // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2221.743400711154!2d35.01755517488753!3d48.53106498369886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sua!4v1587240410732!5m2!1sru!2sua"
                  src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d100678.70249104303!2d35.0052070962386!3d48.46350839021087!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sua!4v1653920754881!5m2!1sru!2sua"
                  allowFullScreen
                />
              </Spacer>
            </Container>
          </PageContent>
        </Container>
      </Container>
    </MainContentWrapper>
  )
}

export default HomePage
