import classNames from 'classnames'
import classNamesBind from 'classnames/bind'

import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import ThemeContext from '../ThemeContext'

import styles from './Divider.module.scss'

const propTypes = {
  text: PropTypes.string,
}

const cx = classNamesBind.bind(styles)

const Divider = props => {
  const { text, ...customProps } = props
  const theme = useContext(ThemeContext)
  const dividerClassNames = classNames(
    cx([{ divider: !text }, { 'divider-container': text }, theme.className]),
    customProps.className
  )

  if (!text) {
    return (
      <hr {...customProps} className={dividerClassNames} aria-hidden="true" />
    )
  }

  return (
    <div {...customProps} className={dividerClassNames}>
      <span className={cx(['divider-text'])}>{text}</span>
    </div>
  )
}

Divider.propTypes = propTypes
export default Divider
