import cn from 'classnames'
import React, { useState, useRef } from 'react'

import useOnClickOutside from '../../../hooks/useClickOutside'
import useUnHover from '../../../hooks/useUnHover'
import Text from '../Text'

import styles from './Navigation-dropdown.module.scss'

const NavigationDropdown = ({ text, children, isMobileMenuShowed }) => {
  const [isOpened, setIsOpened] = useState(false)
  const ref = useRef()

  const handler = () => {
    setIsOpened(false)
  }

  const toggleList = () => {
    setIsOpened(!isOpened)
  }

  useOnClickOutside(ref, handler)
  useUnHover(ref, handler)

  return (
    <li
      className={cn(
        styles.dropdown,
        isOpened ? styles.open : '',
        isMobileMenuShowed ? styles.mobileOpen : styles.mobileClosed
      )}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={toggleList} className={styles.toggle} data-toggle="dropdown">
        <Text>{text}</Text>
        <b className={styles.caret} />
      </a>
      <ul className={styles.menu}>{children}</ul>
    </li>
  )
}

export default NavigationDropdown
