import React from 'react'

import Container from '../Container'
import Spacer from '../Spacer'

import styles from './PageHeader.module.scss'

const PageHeader = ({ children }) => {
  return (
    <Spacer
      data-aos="fade-down"
      data-aos-delay="400"
      marginTop="large+4"
      className={styles.header}
    >
      <Container>{children}</Container>
    </Spacer>
  )
}

export default PageHeader
