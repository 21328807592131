import AOS from 'aos'
import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import { useEchListDispatch } from '../../context/EchListContext'
import useAuth from '../../hooks/useAuth'
import getEchs from '../../services/echinodoruses-service'
import Admin from '../pages/Admin'
import AuthPage from '../pages/AuthPage'
import Blog from '../pages/Blog'
import Catalog from '../pages/Catalog'
import Description from '../pages/Description'
import EchItem from '../pages/Ech-page'
import Farm from '../pages/Farm'
import HomePage from '../pages/Home-page'
import ErrorPage from '../pages/Page-404'
import Purchase from '../pages/Purchase'
import Footer from '../primitives/Footer'
import Navigation from '../primitives/Navigation'
import 'aos/dist/aos.css'
import Spinner from '../primitives/Spinner'

AOS.init()

const App = () => {
  const dispatch = useEchListDispatch()
  useEffect(() => {
    getEchs()
      .then(data => echLoaded(data))
      .catch(err => echsError(err))
  })

  const echLoaded = newEch => {
    dispatch({
      type: 'FETCH_ECH_SUCCESS',
      payload: newEch,
    })
  }

  const echsError = error => {
    dispatch({
      type: 'FETCH_ECH_FAILURE',
      payload: error,
    })
  }

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
  const checkMenuState = isMobileMenuShowed => {
    setMobileMenuOpened(isMobileMenuShowed)
  }
  const { token, login, logout, userId, ready } = useAuth()
  const isAuthenticated = !!token

  if (!ready) {
    return <Spinner />
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated,
      }}
    >
      <Router>
        <Navigation checkMenuState={checkMenuState} />
        <div id="page-top" data-spy="scroll" data-target=".navbar-custom" />
        <Routes>
          <Route
            path="/"
            element={<HomePage mobileMenuOpened={mobileMenuOpened} />}
          />
          {isAuthenticated ? (
            <Route
              path="/admin/"
              element={<Admin mobileMenuOpened={mobileMenuOpened} />}
            />
          ) : (
            <Route
              path="/login/"
              element={<AuthPage mobileMenuOpened={mobileMenuOpened} />}
            />
          )}
          {isAuthenticated ? (
            <Route
              path="/admin/farm/"
              element={<Farm mobileMenuOpened={mobileMenuOpened} />}
            />
          ) : (
            <Route
              path="/login/"
              element={<AuthPage mobileMenuOpened={mobileMenuOpened} />}
            />
          )}
          <Route
            path="/description/"
            element={<Description mobileMenuOpened={mobileMenuOpened} />}
          />
          <Route
            path="/blog/"
            element={<Blog mobileMenuOpened={mobileMenuOpened} />}
          />
          <Route
            path="/purchase/"
            element={<Purchase mobileMenuOpened={mobileMenuOpened} />}
          />
          <Route
            path="/catalog/"
            element={<Catalog mobileMenuOpened={mobileMenuOpened} />}
          />
          <Route
            path="/echinodorus/:id/"
            element={<EchItem mobileMenuOpened={mobileMenuOpened} />}
          />
          <Route
            path="*"
            element={<ErrorPage mobileMenuOpened={mobileMenuOpened} />}
          />
        </Routes>
        <Footer />
      </Router>
    </AuthContext.Provider>
  )
}

export default App
