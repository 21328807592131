import cn from 'classnames'
import React from 'react'

import { useScrollTo } from '../../../hooks/useScrollTo'
import { deleteEch } from '../../../services/echinodoruses-service'

import styles from './EchDescription.module.scss'

const EchDescription = ({ ech, addEchToForm }) => {
  const {
    _id,
    id,
    // price,
    availability,
    isHybrid,
    nursery,
    name,
    nameUa,
    nameRu,
    height,
    // width,
    descriptionUa,
    descriptionRu,
    description,
  } = ech

  const useEditEch = event => {
    addEchToForm(ech)
    useScrollTo(event)
  }

  return (
    <div data-rowid={_id} className={cn(styles.formContent)}>
      <div className={styles.hidden}>{_id}</div>
      <div className={styles.block}>id: {id}</div>
      {/*<div>price: {price}</div>*/}
      <div className={styles.block}>
        availability: {availability.toString()}
      </div>
      <div className={styles.block}>isHybrid: {isHybrid.toString()}</div>
      <div className={styles.block}>nursery: {nursery}</div>
      <div className={styles.block}>name: {name}</div>
      <div className={styles.block}>nameUa: {nameUa}</div>
      <div className={styles.block}>nameRu: {nameRu}</div>
      <div className={styles.block}>height: {height}</div>
      {/*<div>width: {width}</div>*/}
      <div className={styles.block}>descriptionUa: {descriptionUa}</div>
      <div className={styles.block}>descriptionRu: {descriptionRu}</div>
      <div className={styles.block}>description: {description}</div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          data-state="page-top"
          onClick={useEditEch}
          className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
        >
          Change
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => deleteEch(_id)}
          className={cn(styles.btn, styles.btnSm, styles.btnPrimary)}
        >
          Remove
        </a>
      </div>
    </div>
  )
}

export default EchDescription
