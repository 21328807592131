// import React from 'react'
// import styles from './Text.module.scss'
// import cn from 'classnames'
//
// const Text = ({ children, className }) => {
//   return <div className={cn(className)}>{children}</div>
// }
//
// export default Text
import classNames from 'classnames'
import classNamesBind from 'classnames/bind'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import ThemeContext from '../ThemeContext'

import styles from './Text.module.scss'

const cx = classNamesBind.bind(styles)

const LetterSpacing = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
}

const TextWeight = {
  700: 700,
  400: 400,
  300: 300,
  200: 200,
}

const TextFontSize = {
  100: 100,
  92: 92,
  72: 72,
  64: 64,
  56: 56,
  50: 50,
  46: 46,
  36: 36,
  32: 32,
  24: 24,
  20: 20,
  18: 18,
  16: 16,
  14: 14,
  12: 12,
  10: 10,
}

const propTypes = {
  /**
   *  ToDo: Fix Children PropTypes by adding intl
   */
  // children: PropTypes.node.isRequired,
  isItalic: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  isVisuallyHidden: PropTypes.bool,
  isWordWrapped: PropTypes.bool,
  fontSize: PropTypes.oneOf([
    100, 92, 72, 64, 56, 50, 46, 36, 32, 24, 20, 18, 16, 14, 12, 10,
  ]),

  letterSpacing: PropTypes.oneOf([1, 2, 3, 4, 5]),
  weight: PropTypes.oneOf([200, 300, 400, 700]),
  colorClass: PropTypes.string,
}

const defaultProps = {
  isWordWrapped: false,
  isItalic: false,
  isVisuallyHidden: false,
  isUpperCase: false,
}

const Text = ({
  children,
  isVisuallyHidden,
  isItalic,
  fontSize,
  isUpperCase,
  weight,
  letterSpacing,
  isWordWrapped,
  colorClass,
  ...customProps
}) => {
  const theme = useContext(ThemeContext)

  const attributes = { ...customProps }
  const TextClassNames = classNames(
    cx([
      'text',
      { 'inherit-color': !colorClass },
      { italic: isItalic },
      { 'upper-case': isUpperCase },
      { 'word-wrap': isWordWrapped },
      { 'visually-hidden': isVisuallyHidden },
      { [`font-size-${fontSize}`]: fontSize },
      { [`font-weight-${weight}`]: weight },
      { [`letter-spacing-${letterSpacing}`]: letterSpacing },
      colorClass,
      theme.className,
    ]),
    attributes.className
  )

  return (
    <span {...attributes} className={TextClassNames}>
      {children}
    </span>
  )
}

Text.propTypes = propTypes
Text.defaultProps = defaultProps

export default Text
export { TextWeight, TextFontSize, LetterSpacing }
