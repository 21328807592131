import cn from 'classnames'
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import ErrorIndicator from '../../Error-indicator'

import testStyles from '../Text/Text.module.scss'

import styles from './Search-panel.module.scss'

const SearchPanel = ({ echs }) => {
  const [echsArray, setEchsArray] = useState([])
  const [isFocusIn, setIsFocusIn] = useState('out')
  const { t } = useTranslation()

  const setFocus = () => {
    setIsFocusIn('in')
  }
  const removeFocus = () => {
    setIsFocusIn('out')
  }
  const onSearchChange = e => {
    const term = e.target.value
    const eArray = []
    let i = 0
    eArray.length = 0

    echs.forEach(ech => {
      if (term.length <= 1) {
        eArray.length = 0
        setEchsArray(eArray)
        return null
      }
      if (
        ech.nameRu.toLowerCase().includes(term.toLowerCase()) ||
        ech.nameUa.toLowerCase().includes(term.toLowerCase()) ||
        ech.name.toLowerCase().includes(term.toLowerCase())
      ) {
        i++
        eArray.push(ech)
        setEchsArray(eArray)
      }
      if (i === 0) {
        eArray.length = 0
        setEchsArray(eArray)
      }
    })
  }
  const SearchList = ({ echsArr }) => {
    const useScrollTo = event => {
      event.target.click()
    }

    return echsArray.map(ech => {
      if (echsArr.includes(ech)) {
        return (
          <li key={ech.id}>
            <Link
              to={`/echinodorus/${ech.id}/`}
              data-state="page-top"
              onMouseDown={useScrollTo}
            >
              {ech.nameRu}
            </Link>
          </li>
        )
      }
      return null
    })
  }

  return (
    <li>
      <input
        type="text"
        onFocus={setFocus}
        onBlur={removeFocus}
        className={cn(styles.panel, testStyles.body2)}
        placeholder={t('Search-panel.Find')}
        onChange={onSearchChange}
      />
      <ul className={cn(styles.dropdown, styles[`${isFocusIn}`])}>
        {echsArray && <SearchList echsArr={echsArray} />}
      </ul>
    </li>
  )
}

const SearchPanelContainer = () => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState
  if (loading) {
    return
  }
  if (error) {
    return <ErrorIndicator />
  }
  if (echs.length > 0) {
    return <SearchPanel echs={echs} />
  }
}

export default SearchPanelContainer
