import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import MainContentWrapper from '../../primitives/MainContentWrapper'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'

import styles from './Page-404.module.scss'

const ErrorPage = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Spacer paddingTop="large+4">
        <Spacer
          className={cn(styles.error)}
          paddingTop="large+4"
          paddingBottom="large+4"
        >
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={700}
            fontSize={36}
          >
            {t('404.Text')}
          </Text>
        </Spacer>
      </Spacer>
    </MainContentWrapper>
  )
}

export default ErrorPage
