import cn from 'classnames'
import React from 'react'

import { Link } from 'react-scroll'

import Angle from '../Angle'
import angleStyles from '../Angle/Angle.module.scss'

import styles from './Shake-scroll-bottom.module.scss'

const ShakeScrollBottom = () => {
  return (
    <div
      className={cn(
        styles.scroll,
        styles.shake,
        styles.animated,
        styles.marginbot30
      )}
    >
      <Link
        to="page-top"
        smooth={true}
        offset={0}
        duration={1000}
        className={styles.circle}
      >
        <Angle
          className={cn(angleStyles.fa, angleStyles.doubleUp, styles.animated)}
        />
      </Link>
    </div>
  )
}

export default ShakeScrollBottom
