import cn from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import { useEchListDispatch } from '../../../context/EchListContext'
import { useScrollTo } from '../../../hooks/useScrollTo'
import Image from '../Image'
import imageStyles from '../Image/Image.module.scss'
import Spacer from '../Spacer'
import Text from '../Text'
import textStyles from '../Text/Text.module.scss'

import styles from './TitleEchContainer.module.scss'

const TitleEchContainer = ({
  filter,
  titleText,
  mainText,
  className,
  ...props
}) => {
  const dispatch = useEchListDispatch()

  const activateFilter = activeFilter => {
    dispatch({
      type: 'FETCH_ECH_FILTER',
      payload: activeFilter,
    })
  }

  const filterTitleClick = type => {
    activateFilter(type)
  }

  return (
    <Link
      to="catalog"
      data-aos={props['data-aos']}
      data-aos-delay={props['data-aos-delay']}
      data-state="page-top"
      onClick={useScrollTo}
      onMouseUp={() => filterTitleClick(filter)}
      className={cn(styles.link, styles.tile, className)}
    >
      <Spacer
        marginTop="large"
        marginRight="large+2"
        marginBottom="large+4"
        marginLeft="large+2"
      >
        <Text
          letterSpacing={1}
          isUpperCase
          isWordWrapped
          weight={200}
          fontSize={12}
          className={textStyles.centeredText}
        >
          {titleText}
        </Text>
      </Spacer>
      <Spacer marginRight="large+2" marginLeft="large+2">
        <Text
          isWordWrapped
          fontSize={14}
          className={cn(textStyles.centeredText, textStyles.mainText)}
        >
          {mainText}
        </Text>
      </Spacer>
      <Spacer
        paddingTop="large+4"
        paddingRight="medium"
        paddingBottom="large"
        paddingLeft="medium"
      >
        <Image
          src={require(`../../img/sorts/${filter}.jpg`)}
          alt=""
          className={cn(imageStyles.circle, styles.bottom, styles.bottomImage)}
        />
      </Spacer>
    </Link>
  )
}

export default TitleEchContainer
