import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'

// import { Link } from 'react-router-dom'
// import { useScrollTo } from '../../../hooks/useScrollTo'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'

import textStyles from '../../primitives/Text/Text.module.scss'

// import styles from './Purchase.module.scss'

const Purchase = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text letterSpacing={1} isUpperCase isWordWrapped fontSize={36}>
            {t('PurchasePage.PageTitle')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4" className={cn('page-content')}>
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (19).png')} alt="19" />
            <img src={require('../../img/icons/G12 (7).png')} alt="7" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('PurchasePage.PurchaseTitle')}
          </Text>
          <Spacer
            marginTop="large+3"
            marginBottom="large+4"
            className={cn('max-width')}
          >
            <Text
              isWordWrapped
              fontSize={14}
              className={cn(textStyles.justifyText, textStyles.mainText)}
            >
              {/*Для приобретения растений вы можете связаться с нами по номеру <strong>+380 (Telegram, Viber)</strong>,<br/>
                                        либо отправить свои пожелания на почту <strong>ech.blackwater@gmail.com</strong><br/>*/}
              {t('PurchasePage.Quesstion')}{' '}
              <Text weight={700}>ech.blackwater@gmail.com</Text>
              <br />
              {/*{t('PurchasePage.Link')}{' '}*/}
              {/*<Link to="/farm/" data-state="page-top" onClick={useScrollTo}>*/}
              {/*  <Text weight={700} colorClass={styles['ech-collection']}>*/}
              {/*    {t('PurchasePage.Collection')}*/}
              {/*  </Text>*/}
              {/*</Link>*/}
            </Text>
          </Spacer>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default Purchase
