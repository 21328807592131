import React, { useEffect, useState } from 'react'

import styles from './Input.module.scss'

const Input = ({ name, type, formValue, getFormValue, placeholder }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(formValue)
  }, [formValue])

  const handleChange = event => {
    setValue(event.target.value)
    getFormValue(event.target.value, name)
  }

  return (
    <input
      className={styles.formControl}
      name={name}
      placeholder={placeholder}
      value={value}
      type={type}
      onChange={handleChange}
    />
  )
}

export default Input
