import cn from 'classnames'
import React from 'react'

import styles from './Container.module.scss'

const Container = ({ children, className, ...props }) => {
  return (
    <div
      id={props.id}
      className={cn(styles.container, className)}
      data-aos={props['data-aos']}
      data-aos-delay={props['data-aos-delay']}
    >
      {children}
    </div>
  )
}

export default Container
