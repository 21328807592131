import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'

import textStyles from '../../primitives/Text/Text.module.scss'

import descriptionStyles from './Description.module.scss'

const SamuliLehtonen = () => {
  const { t } = useTranslation()
  return (
    <Text
      isWordWrapped
      fontSize={14}
      className={cn(textStyles.justifyText, textStyles.mainText)}
    >
      {t('Description.SamuliLehtonen')}
      <br />
      <br />
      {t('Description.Genus')}
      <br />
      <br />
      {t('Description.Sections')}
      <br />
      <br />
      {t('Description.Species')}
      <br />
      <br />
      <table className={cn(descriptionStyles.table)}>
        <tbody>
          <tr>
            <td>
              <Text weight={700}>{t('Description.TableSpecie')}</Text>
            </td>
            <td>
              <Text weight={700}>{t('Description.TableRataj')}</Text>
            </td>
            <td>
              <Text weight={700}>{t('Description.TableHaynes')}</Text>
            </td>
            <td>
              <Text weight={700}>{t('Description.TableLehtonen')}</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus&nbsp;amazonicus</td>
            <td>amazonicus</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus amphibius</td>
            <td>amphibius</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus andrieuxii</td>
            <td>andrieuxii</td>
            <td>subalatus andrieuxii</td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus&nbsp;angustifolius</td>
            <td>angustifolius</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus argentinensis</td>
            <td>argentinensis</td>
            <td>grandiflorus</td>
            <td>
              <Text>longiscapus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus aschersonianus</td>
            <td>aschersonianus</td>
            <td>aschersonianus</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus aureobrunneus</td>
            <td>osiris</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus aureus</td>
            <td>grandiflorus</td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus&nbsp;austroamericanus</td>
            <td>austroamericanus</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus barthii</td>
            <td>cordifolius fluitans</td>
            <td>uruguayensis</td>
            <td>
              <Text>x cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus berteroanum</td>
            <td>berteroi</td>
            <td>berteroi</td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus bleheri</td>
            <td>bleheri</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus bolivianus</Text>
            </td>
            <td></td>
            <td>
              <Text>bolivianus</Text>
            </td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus bracteatus</Text>
            </td>
            <td>
              <Text>bracteatus</Text>
            </td>
            <td>
              <Text>bracteatus</Text>
            </td>
            <td>
              <Text>bracteatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus brasiliense</td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus brevipedicellatus</td>
            <td>amazonicus</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus claussenii</td>
            <td>grandiflorus &nbsp;claussenii</td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus cordifolium</td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus cylindricus</Text>
            </td>
            <td>
              <Text>cylindricus</Text>
            </td>
            <td>paniculatus</td>
            <td>
              <Text>cylindricus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus de witi</td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus decumbens</Text>
            </td>
            <td></td>
            <td>
              <Text>decumbens</Text>
            </td>
            <td>
              <Text>decumbens</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus dubius</td>
            <td></td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus ecostatus</td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus efenestratus</td>
            <td>bracteatus efenestratus</td>
            <td>bracteatus efenestratus</td>
            <td>
              <Text>bracteatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus eglandulosus</td>
            <td></td>
            <td>eglandulosus</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus ellipticus</td>
            <td>
              <Text>palaefolius</Text>
            </td>
            <td>
              <Text>palaefolius</Text>
            </td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus floribundum</td>
            <td>grandiflorus</td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>floribundus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus fluitans</td>
            <td>fluitans</td>
            <td>cordifolius fluitans</td>
            <td>
              <Text>cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus frankoniana</td>
            <td>palaefolius latifolius</td>
            <td>palaefolius</td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus gabrieli</td>
            <td></td>
            <td></td>
            <td>
              <Text>x cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus glandulosus</Text>
            </td>
            <td>
              <Text>glandulosus</Text>
            </td>
            <td>
              <Text>glandulosus</Text>
            </td>
            <td>
              <Text>glandulosus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus glaucus</Text>
            </td>
            <td>
              <Text>glaucus</Text>
            </td>
            <td>paniculatus</td>
            <td>
              <Text>glaucus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus gracilis</td>
            <td>gracilis</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus grandiflorus</Text>
            </td>
            <td>
              <Text>grandiflorus</Text>
            </td>
            <td>
              <Text>grandiflorus</Text>
            </td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus grisebachii</Text>
            </td>
            <td>
              <Text>grisebachii</Text>
            </td>
            <td>
              <Text>grisebachii</Text>
            </td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus guianensis</td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus horemanii</td>
            <td>horemanii</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
            <td>
              <Text>horizontalis</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus inpai</Text>
            </td>
            <td></td>
            <td>subalatus</td>
            <td>
              <Text>inpai</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus intermedius</td>
            <td>quadricostatus xinguensis</td>
            <td>bolivianus</td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus isthmicus</td>
            <td>isthmicus</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus janii</td>
            <td></td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus lanceolatus</td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus lanceolatus</Text>
            </td>
            <td>
              <Text>lanceolatus</Text>
            </td>
            <td>
              <Text>lanceolatus</Text>
            </td>
            <td>
              <Text>lanceolatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus latifolius</td>
            <td>latifolius</td>
            <td>bolivianus</td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus leopoldina</td>
            <td>maior</td>
            <td>martii</td>
            <td>
              <Text>martii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus longibracteatus</td>
            <td>grandiflorus longibracteatus</td>
            <td>grandiflorus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus longipetalus</Text>
            </td>
            <td>
              <Text>longipetalus</Text>
            </td>
            <td>
              <Text>longipetalus</Text>
            </td>
            <td>
              <Text>longipetalus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus longiscapus</Text>
            </td>
            <td>
              <Text>longiscapus</Text>
            </td>
            <td>grandiflorus</td>
            <td>
              <Text>longiscapus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus longistylis</td>
            <td>longistylis</td>
            <td>subalatus</td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus macrocarpus</td>
            <td>macrocarpus</td>
            <td>paniculatus</td>
            <td>
              <Text>pubescens</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus macrophyllus</Text>
            </td>
            <td>
              <Text>macrophyllus</Text>
            </td>
            <td>
              <Text>macrophyllus</Text>
            </td>
            <td>
              <Text>macrophyllus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus magdalenensis</td>
            <td>quadricostatus magdalenensis</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus maior</td>
            <td>maior</td>
            <td>martii</td>
            <td>
              <Text>martii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus majus</td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus martii</Text>
            </td>
            <td>maior</td>
            <td>
              <Text>martii</Text>
            </td>
            <td>
              <Text>martii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus medium</td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus michaeli</td>
            <td>longiscapus</td>
            <td>grandiflorus</td>
            <td>
              <Text>longiscapus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus minus</td>
            <td>palaefolius minus</td>
            <td>palaefolius</td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus muricatus</td>
            <td>grandiflorus</td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus nulliglandulosus</td>
            <td>aschersonianus nulliglandulosus</td>
            <td>aschersonianus</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus nymphaeifolius</Text>
            </td>
            <td>
              <Text>nymphaeifolius</Text>
            </td>
            <td>
              <Text>nymphaeifolius</Text>
            </td>
            <td>
              <Text>nymphaeifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus opacus</td>
            <td>opacus</td>
            <td>x uruguayensis</td>
            <td>
              <Text>x uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus osiris</td>
            <td>osiris</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus ovalis</td>
            <td>ovalis</td>
            <td>cordifolius</td>
            <td>
              <Text>cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus ovate</td>
            <td>andrieuxii</td>
            <td>subalatus andrieuxii</td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus palaefolius</Text>
            </td>
            <td>
              <Text>palaefolius</Text>
            </td>
            <td>
              <Text>palaefolius</Text>
            </td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus paniculatus</Text>
            </td>
            <td>
              <Text>paniculatus</Text>
            </td>
            <td>
              <Text>paniculatus</Text>
            </td>
            <td>
              <Text>paniculatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus parviflorus</td>
            <td>parviflorus</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus parvulus</td>
            <td>tenellus parvulus</td>
            <td>tenellus</td>
            <td>
              <Text>tenellus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus patagonicus</td>
            <td>berteroi patagonicus</td>
            <td>berteroi</td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus pellucidus</td>
            <td>pellucidus</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus peruensis</td>
            <td>parviflorus</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus platyphylla</td>
            <td>bleheri</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus portoalegrensis</td>
            <td>portoalegrensis</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus proliferatus</td>
            <td>scaber proliferatus</td>
            <td>macrophyllus scaber</td>
            <td>
              <Text>scaber</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus pubescens</Text>
            </td>
            <td>
              <Text>pubescens</Text>
            </td>
            <td>
              <Text>pubescens</Text>
            </td>
            <td>
              <Text>pubescens</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus punctatus</td>
            <td></td>
            <td>grandiflorus aureus</td>
            <td>
              <Text>grandiflorus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus pusillus</td>
            <td></td>
            <td>aschersonianus</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus quadricostatus</td>
            <td>quadricostatus</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus radicans</td>
            <td>
              <Text>cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus rangeri</td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus reticulatus</td>
            <td></td>
            <td>reticulatus</td>
            <td>
              <Text>longipetalus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus rostratum</td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus rubra</td>
            <td>osiris</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus rubronervis</td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus scaber</Text>
            </td>
            <td>
              <Text>scaber</Text>
            </td>
            <td>macrophyllus scaber</td>
            <td>
              <Text>scaber</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus schlueteri</td>
            <td></td>
            <td>
              <Text>cordifolius</Text>
            </td>
            <td>
              <Text>cordifolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus sellowianus</td>
            <td>longiscapus</td>
            <td>grandiflorus</td>
            <td>
              <Text>longiscapus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus sprengelii</td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
            <td>
              <Text>berteroi</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
            <td>
              <Text>subalatus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
            <td>
              <Text>tenellus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus teretoscapus</td>
            <td></td>
            <td>teretoscapus</td>
            <td>
              <Text>glaucus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus tocantins</td>
            <td>parviflorus</td>
            <td>grisebachii</td>
            <td>
              <Text>grisebachii</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus trialatus</Text>
            </td>
            <td>
              <Text>trialatus</Text>
            </td>
            <td>
              <Text>trialatus</Text>
            </td>
            <td>
              <Text>trialatus</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus tunicatus</Text>
            </td>
            <td>
              <Text>tunicatus</Text>
            </td>
            <td>
              <Text>tunicatus</Text>
            </td>
            <td>
              <Text>tunicatus</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus undulatus</td>
            <td>horemanii</td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>
              <Text>Echinodorus uruguayensis</Text>
            </td>
            <td>
              <Text>uruguayensis</Text>
            </td>
            <td>
              <Text>uruguayensis</Text>
            </td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus veronicae</td>
            <td></td>
            <td>uruguayensis</td>
            <td>
              <Text>uruguayensis</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus virgatus</td>
            <td>virgatus</td>
            <td>virgatus</td>
            <td>
              <Text>palaefolius</Text>
            </td>
          </tr>
          <tr>
            <td>Echinodorus xinguensis</td>
            <td>quadricostatus xinguensis</td>
            <td>bolivianus</td>
            <td>
              <Text>bolivianus</Text>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
    </Text>
  )
}
const Description = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text isUpperCase isWordWrapped fontSize={36}>
            {t('Description.Title')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (14).png')} alt="" />
            <img src={require('../../img/icons/G12 (20).png')} alt="" />
          </ServiceIcon>
          <Text
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('Description.Title')}
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+4">
            <Text
              isWordWrapped
              fontSize={14}
              className={cn(textStyles.justifyText, textStyles.mainText)}
            >
              {t('Description.Text_1')}
              <br />
              {t('Description.Text_2')}
              <br />
              {t('Description.Text_3')}
              <br />
              {t('Description.Text_4')}
              <br />
              {t('Description.Text_5')}
              <br />
              {t('Description.Text_6')}
              <br />
              {t('Description.Text_7')}
              <br />
            </Text>
          </Spacer>
        </Spacer>{' '}
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12.png')} alt="" />
            <img src={require('../../img/icons/G12 (6).png')} alt="" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('Growing.Title')}
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+4">
            <Text
              isWordWrapped
              fontSize={14}
              className={cn(textStyles.justifyText, textStyles.mainText)}
            >
              <br />
              {t('Growing.Text_1')}
              <br />
              {t('Growing.Text_2')}
              <br />
              {t('Growing.Text_3')}
              <br />
              {t('Growing.Text_4')}
              <br />
              {t('Growing.Text_5')}
              <br />
              {t('Growing.Text_6')}
              <br />
              {t('Growing.Text_7')}
              <br />
              {t('Growing.Text_8')}
              <br />
              {t('Growing.Text_9')}
              <br />
              {t('Growing.Text_10')}
              <br />
              {t('Growing.Text_11')}
              <br />
              {t('Growing.Text_12')}
              <br />
              {t('Growing.Text_13')}
              <br />
              {t('Growing.Text_14')}
              <br />
              {t('Growing.Text_15')}
              <br />
              {t('Growing.Text_16')}
              <br />
              {t('Growing.Text_17')}
              <br />
              {t('Growing.Text_18')}
              <br />
              {t('Growing.Text_19')}
              <br />
            </Text>
          </Spacer>
        </Spacer>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (17).png')} alt="" />
            <img src={require('../../img/icons/G12 (5).png')} alt="" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('Breeding.Title')}
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+4">
            <Text
              isWordWrapped
              fontSize={14}
              className={cn(textStyles.justifyText, textStyles.mainText)}
            >
              {t('Breeding.Text_1')}
              <br />
              {t('Breeding.Text_2')}
              <br />
              {t('Breeding.Text_3')}
              <br />
              {t('Breeding.Text_4')}
              <br />
              {t('Breeding.Text_5')}
              <br />
              {t('Breeding.Text_6')}
              <br />
              {t('Breeding.Text_7')}
              <br />
              {t('Breeding.Text_8')}
              <br />
              {t('Breeding.Text_9')}
              <br />
              {t('Breeding.Text_10')}
              <br />
              {t('Breeding.Text_11')}
              <br />
            </Text>
          </Spacer>
        </Spacer>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (23).png')} alt="" />
            <img src={require('../../img/icons/G12 (16).png')} alt="" />
          </ServiceIcon>
          <Text
            letterSpacing={1}
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('Description.Synonym')}
          </Text>
          <Spacer marginTop="large+4" marginBottom="large+2">
            <SamuliLehtonen />
          </Spacer>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default Description
