import React from 'react'

import Spacer from '../Spacer'

import styles from './Collection-item.module.scss'

const CollectionItem = ({ ech }) => {
  return (
    <Spacer key={ech.id} className={styles.item}>
      <Spacer className={styles.id}>{ech.id}</Spacer>
      <Spacer className={styles.name}>{ech.name}</Spacer>
      <Spacer className={styles.nameUa}>{ech.nameUa}</Spacer>
      <Spacer className={styles.nameRu}>{ech.nameRu}</Spacer>
      <Spacer className={styles.height}>{ech.height}</Spacer>
      {/*      <Spacer className={styles.price}>{ech.price}</Spacer>
      <Spacer className={styles.availability}>{ech.availability}</Spacer>*/}
    </Spacer>
  )
}

export default CollectionItem
