import cn from 'classnames'
import React from 'react'

import styles from './Image.module.scss'

const Image = ({ src, alt, className }) => {
  return <img className={cn(styles.image, className)} src={src} alt={alt} />
}

export default Image
