import cn from 'classnames'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Angle from '../../primitives/Angle'
import angleStyles from '../../primitives/Angle/Angle.module.scss'
import Divider from '../../primitives/Divider'
import EchFilter from '../../primitives/Ech-filter/index'
import EchList from '../../primitives/Ech-list/index'
import Intro from '../../primitives/Intro'
import MainContentWrapper from '../../primitives/MainContentWrapper'
import PageContent from '../../primitives/Page-content'
import PageHeader from '../../primitives/Page-header'
import ServiceIcon from '../../primitives/Service-icon'
import Spacer from '../../primitives/Spacer'
import Text from '../../primitives/Text'
import textStyles from '../../primitives/Text/Text.module.scss'

const Catalog = ({ mobileMenuOpened }) => {
  const { t } = useTranslation()
  return (
    <MainContentWrapper mobileMenuOpened={mobileMenuOpened}>
      <Intro />
      <PageHeader>
        <Spacer marginTop="large+3">
          <Text isUpperCase isWordWrapped fontSize={36}>
            {t('Menu.Catalog')}
          </Text>
        </Spacer>
        <Spacer marginTop="large+2" marginBottom="large">
          <Angle className={cn(angleStyles.down, angleStyles.x2)} />
        </Spacer>
      </PageHeader>
      <PageContent>
        <Divider />
        <Spacer marginTop="large+4">
          <ServiceIcon>
            <img src={require('../../img/icons/G12 (3).png')} alt="" />
            <img src={require('../../img/icons/G12 (18).png')} alt="" />
          </ServiceIcon>
          <Text
            isUpperCase
            isWordWrapped
            weight={200}
            fontSize={12}
            className={textStyles.centeredText}
          >
            {t('CatalogPage.Catalog')}
          </Text>
          <Spacer marginTop="large+3" marginBottom="large+4">
            <EchFilter />
            <EchList />
          </Spacer>
        </Spacer>
      </PageContent>
    </MainContentWrapper>
  )
}

export default Catalog
