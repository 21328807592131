import React from 'react'

import { Link } from 'react-router-dom'

import { useScrollTo } from '../../../hooks/useScrollTo'

import styles from './NavigationLink.module.scss'

const NavigationLink = ({ to, text, className, dataState }) => {
  return (
    <li className={styles.link}>
      <Link
        to={to}
        data-state={dataState}
        className={styles.name}
        state={{ event: dataState }}
        onClick={useScrollTo}
      >
        <div className={className}>{text}</div>
      </Link>
    </li>
  )
}

export default NavigationLink
