import classNames from 'classnames/bind'
import React from 'react'

import { useTranslation } from 'react-i18next'

// import { activateFilterCollection } from '../../../actions'
import styles from '../Ech-filter/Ech-filter.module.scss'
import Text from '../Text'

import filterStyles from './Collection-filter.module.scss'

const cx = classNames.bind(styles)

// let classNameActive = ''

const CollectionFilter = () => /* {
       activateFilterCollection
      }*/ {
  const { t } = useTranslation()
  /*  const direction = ''
              const buttonState = ''*/

  const buttons = [
    { action: 'id', label: t('CollectionFilter.ID'), direction: 'up' },
    {
      action: 'name',
      label: t('CollectionFilter.NameEnglish'),
      direction: 'down',
    },
    {
      action: 'nameUa',
      label: t('CollectionFilter.NameUa'),
      direction: 'down',
    },
    {
      action: 'nameRu',
      label: t('CollectionFilter.Name'),
      direction: 'down',
    },
    {
      action: 'height',
      label: t('CollectionFilter.Height'),
      direction: 'down',
    },
  ]

  return (
    <div className={filterStyles.filter}>
      {buttons.map(button => {
        /*        if (button.action === classNameActive) {
                                                                buttonState = 'Active'
                                                                if (button.direction === 'up') {
                                                                  button.direction = 'down'
                                                                  direction = direction + 'Down'
                                                                } else {
                                                                  button.direction = 'up'
                                                                  direction = direction + 'Up'
                                                                }
                                                              } else {
                                                                direction = ''
                                                                button.direction = 'up'
                                                              }*/
        return (
          <button
            className={styles.tab}
            /*className={
                                                                                                              filterStyles['button' + direction] +
                                                                                                ' ' +
                                                                                                styles['button' + buttonState]
                                                                                              }*/
            key={button.action}
            type={button.action}
            /*            onClick={() => {
                                                                                                activateFilterCollection(button.action, button.direction)
                                                                                              }}*/
          >
            <Text
              weight={400}
              isUpperCase
              isWordWrapped
              fontSize={12}
              colorClass={cx('white')}
            >
              {button.label}
            </Text>
          </button>
        )
      })}
    </div>
  )
}

export default CollectionFilter
