import cn from 'classnames'
import React from 'react'

import styles from './Angle.module.scss'

const Angle = ({ className }) => {
  return <i className={cn(styles.fa, className)} />
}

export default Angle
