import React from 'react'
import { Link } from 'react-router-dom'

import { useEchList } from '../../../context/EchListContext'
import useRandomPromoEchs from '../../../hooks/useRandomPromoEchs'
import { useScrollTo } from '../../../hooks/useScrollTo'
import ErrorIndicator from '../../Error-indicator'
import EchLiItem from '../Ech-li-item'
import stylesList from '../Ech-list/Ech-list.module.scss'
import Spinner from '../Spinner'

const EchListPromo = ({ echs }) => {
  const randomIds = useRandomPromoEchs(echs)

  return (
    <div className={stylesList.list}>
      {echs
        .filter(ech => ech.name.length > 0)
        .filter(ech => randomIds.includes(ech.id))
        .map(ech => {
          return (
            <Link
              key={ech.id}
              to={`/echinodorus/${ech.id}/`}
              data-state="page-top"
              onClick={useScrollTo}
            >
              <EchLiItem ech={ech} />
            </Link>
          )
        })}
    </div>
  )
}

const EchListPromoContainer = ({ promo }) => {
  const echLoadingState = useEchList()
  const { echs, loading, error } = echLoadingState
  if (loading) {
    return
  }
  if (error) {
    return <ErrorIndicator />
  }
  if (echs.length > 0) {
    return <EchListPromo echs={echs} promo={promo} />
  } else {
    return <Spinner />
  }
}

export default EchListPromoContainer
